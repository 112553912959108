import * as React from "react";
import { hydrateRoot } from 'react-dom/client';
// import './client.scss'

type ComponentNameType = 'Navigation' | 'DirectoryCard' | 'Footer' |
                        'ImageGallery' | 'ImageSlider' | 'PackageDetails' |
                        'SupplierSearch' | 'BulkReviews' | 'Messaging' |
                        'MessagingCount' | 'MessagingChannel' | 'MessagingDrawer' |
                        'WeddingSupplierPackages' | 'ConciergeGeneric' | 'WedPoints' | 
                        'SalePoints' | 'AppBanner' | 'WedPointsEarned';

const COMPONENTS_LIST = window['SSR_COMPONENT_LIST']

function hydrateComponents() {
    COMPONENTS_LIST.forEach(({ domID, name, data }: { domID: string, name: ComponentNameType, data: any}) => {
        const Component = React.lazy(() => import(`../../components/${name}.tsx`))
        if (typeof Component === 'object' || typeof Component === 'function') {
            const element = document.getElementById(domID) as Element

            if (!!element)
            {
                hydrateRoot(
                    element,
                    <React.StrictMode>
                        <Component {...data} />
                    </React.StrictMode>
                );
            }
            else {
                console.error(`${domID} element is not present on the page`)
            }
        } else {
            console.error(`${name} is not valid element`)
        }
    })
}
if (import.meta.env.SSR) {
    hydrateComponents()
} else {
    if (document.readyState === "complete" || document.readyState === "interactive") {
        hydrateComponents()
    } else {
        document.addEventListener('DOMContentLoaded', hydrateComponents)
    }
}